<template>
  <div>
    <img src="../../assets/image/index_banner.png" style="width: 100%">
    <div class="company_jianjie">
      <div style="font-size: 18px;text-align: center; margin: 15px 0px">
        <div>公司简介</div>
        <div>Company profile</div>
      </div>

      <div style="font-size: 18px; padding-bottom: 30px;">
        苏州牛气网络科技公司是一家从事货物仓储集运服务商。我司拥有一流的服务团队，经验丰富。从事淘宝集运10多年。
        是为用户挺货物在线采购，仓储，验货，清点，打包，报关运输等一站服务的创新型企业。
        公司一直至于研究和满足海外华人华侨及海外商业客户集运转运需求，深入研究各国海关基本要求，为中国商品转运全球提供一体化解决方案。
        <!-- <p
          style="
            padding: 30px 0px;
            text-align: center;
            color: red;
            font-size: 20px;
          "
        >
          联系电话：<a href="tel:18015631134">18015631134</a>
        </p> -->
        <p
          style="
            padding: 30px 0px 0 0;
            text-align: center;
            color: red;
            font-size: 16px;
          "
        >
          国际快递-美国快递-日本快递-韩国快递-英国快递-澳大利亚快递-加拿大快递快递包裹。
        </p>
      </div>
    </div>
    <div class="image-list">
      <div @click="toPage('/order-search')">
        <img src="../../assets/image/lcys6.png" alt="">
        <div>单号查询</div>
      </div>
      <div @click="toPage('/liucheng')">
        <img src="../../assets/image/lcys.png" alt="">
        <div>下单流程</div>
      </div>
      <div @click="toPage('/fee-compute')">
        <img src="../../assets/image/xwdt.png" alt="">
        <div>运费估算</div>
      </div>
    </div>
    <div class="image-list" style="margin-bottom: 20px">
      <div @click="toPage('/article-list/8/优惠活动')">
        <img src="../../assets/image/lcys4.png" alt="">
        <div>优惠活动</div>
      </div>
      <div @click="toPage('/contact-us')">
        <img src="../../assets/image/lcys5.png" alt="">
        <div>联系我们</div>
      </div>
    </div>
    <div class="image-list-two padding-10">
      <div @click="toPage('/article-list/0/美国')">
        <img src="../../assets/image/c1.png" alt="">
        <div>美国</div>
      </div>
      <div @click="toPage('/article-list/1/英国')">
        <img src="../../assets/image/c2.png" alt="">
        <div>英国</div>
      </div>
      <div @click="toPage('/article-list/2/日本')">
        <img src="../../assets/image/c3.png" alt="">
        <div>日本</div>
      </div>
      <div @click="toPage('/article-list/3/韩国')">
        <img src="../../assets/image/c4.png" alt="">
        <div>韩国</div>
      </div>
    </div>
    <div class="image-list-two padding-10">
      <div @click="toPage('/article-list/4/德国')">
        <img src="../../assets/image/c5.png" alt="">
        <div>德国</div>
      </div>
      <div @click="toPage('/article-list/5/新西兰')">
        <img src="../../assets/image/c6.png" alt="">
        <div>新西兰</div>
      </div>
      <div @click="toPage('/article-list/6/澳大利亚')">
        <img src="../../assets/image/c7.png" alt="">
        <div>澳大利亚</div>
      </div>
      <div @click="toPage('/article-list/7/加拿大')">
        <img src="../../assets/image/c8.png" alt="">
        <div>加拿大</div>
      </div>
    </div>
    <div class="padding-10">
      <div class="nq-ys">
        <img src="../../assets/image/index_xszn.png" style="width: 30px">
        牛气优势
      </div>
      <ul class="nqys-list">
        <li>
          <div class="ys-image">
            <img
              src="../../assets/image/image1.jpg"
              style="height: 110px; width: 100px"
            >
          </div>
          <div class="nqys-content">
            <p style="font-size: 17px; margin-bottom: 8px">
              活动多多，优惠多多
            </p>
            <p>
              牛气专属促销线路，超低价格，再加优惠！无门槛优惠券注册送、晒单送、邀请送，更多惊喜只等你来！
            </p>
          </div>
        </li>
        <li>
          <div class="ys-image">
            <img
              src="../../assets/image/image2.jpg"
              style="height: 110px; width: 100px"
            >
          </div>
          <div class="nqys-content">
            <p style="font-size: 17px; margin-bottom: 8px">
              十年经验，安全可靠
            </p>
            <p>
              牛气集运，十年国际物流经验，仓储集运0丢件！渠道众多，员工专业，服务贴心。总之，选择牛气，选择安全！
            </p>
          </div>
        </li>
        <li>
          <div class="ys-image">
            <img
              src="../../assets/image/image3.jpg"
              style="height: 110px; width: 100px"
            >
          </div>
          <div class="nqys-content">
            <p style="font-size: 17px; margin-bottom: 8px">
              用户信赖，好评如潮
            </p>
            <p>
              十年淘宝集运转运经验，服务于无数海内外华人，您的选择，是我们前进的动力。希望我们的用心，能换来您的倾心。
            </p>
          </div>
        </li>
        <li>
          <div class="ys-image">
            <img
              src="../../assets/image/image4.jpg"
              style="height: 110px; width: 100px"
            >
          </div>
          <div class="nqys-content">
            <p style="font-size: 17px; margin-bottom: 8px">
              专业服务，一心为您
            </p>
            <p>
              专业的集运转运服务，一对一客服耐心积极为您解答所有疑问，无论您是第一次还是很多次，牛气集运，待您如初！
            </p>
          </div>
        </li>
      </ul>
      <br>
    </div>
    <!-- <div class="padding-10">
      <div class="nq-ys">
        <img src="../../assets/image/index_tg.png" style="width: 30px" />
        客户评价
      </div>
      <div class="pj-container">
        <vue-seamless-scroll :data="listData" class="seamless-warp">
          <ul class="item">
            <li v-for="(item, index) in listData" :key="index">
              <div class="title">{{ item.title }}</div>
              <div class="date">{{ item.notes }}</div>
              <div class="name">{{ item.memberName }}</div>
            </li>
          </ul>
        </vue-seamless-scroll>
      </div>
    </div> -->
    <!-- <div class="padding-10 friend-links">
      <span>友情链接：</span>
      <router-link to="/detail">日本快递</router-link>
      <router-link to="/detail">日本快递</router-link>
      <router-link to="/detail">日本快递</router-link>
      <router-link to="/detail">日本快递</router-link>
      <router-link to="/detail">日本快递</router-link>
    </div> -->
    <br>
  </div>
</template>
<script>
import { evaluateInfo } from '../../utils/coupon.js'
export default {
  name: 'Index',
  data() {
    return {
      listData: []
    }
  },
  created() {
    this._getList()
    if (this.$baseInfo.IS_CF == 1) {
      this.$router.push('/login')
    }
  },
  mounted() {},
  methods: {
    _getList() {
      this.$reqPost(evaluateInfo.list).then((res) => {
        if (res.data.code === 200) {
          this.listData = res.data.data
        }
      })
    },
    toPage(url) {
      this.$router.push(url)
    }
  }
}
</script>
<style lang="less" scoped>
@import url("./index.less");

.item .title {
  font-size: 20px;
  color: rgb(63, 134, 241);
  margin-bottom: 5px;
}
.item .date {
  font-size: 14px;
  color: rgb(196, 196, 196);
  margin-bottom: 5px;
}
.item .name {
  text-align: right;
  font-size: 16px;
  color: rgb(158, 157, 157);
  margin-bottom: 5px;
}
</style>
